<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Team Member List</h4>
          <div class="d-flex justify-content-start">
            <router-link v-if="$store.getters['auth/checkAccess']('team-members/create')"
                         :to="'/team-members/create'"
                         class="btn btn-info btn-wd" wide>
              Add New
              <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
            </router-link>
          </div>


        </div>

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :row-key="'id'"
            @reorder="reorder($event)"
            :api-url="'team-members/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

              <template slot-scope="scope">
                <div v-if="column.value === 'image'">
                  <img v-if="scope.row[column.value]" alt="" :src="url +scope.row[column.value]" class="table-image">
                </div>
                <span v-else>{{ scope.row[column.value] }}</span>
              </template>

            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/checkAccess']('questions/change-active-status')"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link
                               class="btn-warning btn-simple btn-link"
                               :to="'/team-members/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>
                </template>


                <a v-if="$store.getters['auth/checkAccess']('team-members/delete')"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)">
                  <i class="fa fa-times"></i></a>
              </template>

            </el-table-column>
          </general-data-table>
        </div>

      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Member?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";



export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
  },

  data() {

    return {
      tableColumns: [
        {label: 'First Name', value: 'first_name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'last_name', minWidth: '200', align: 'center'},
        {label: 'Url', value: 'url', minWidth: '200', align: 'center'},
        {label: 'Image', value: 'image', minWidth: '200', align: 'center'},

      ],
      url : process.env.VUE_APP_SERVER_IMAGE_URL,

      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [
        {
          key: 'team_members.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'team_members.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'team_members.first_name',
          type: 'text',
          frontType:'simple',
          label: 'F Name',
          class: 'col-6',
          value: null,
        },
        {
          key: 'team_members.last_name',
          type: 'text',
          frontType:'simple',
          label: 'L Name',
          class: 'col-6',
          value: null,
        },

        {
          key: 'team_members.url',
          type: 'text',
          frontType:'simple',
          label: 'Url',
          class: 'col-6',
          value: null,
        },
        {
          key: 'team_members.is_active',
          type: 'text',
          frontType:'switch',
          label: 'Is Active',
          class: 'col-6',
          value: null,
        },
      ],

      filterBuilders: {
        teamMembers: []
      },

      filters: {
        member_id: null
      },

      deleteModalVisibility: false,
      toDeleteId: undefined
    }
  },

  async mounted() {
    let response = await this.axios.get("team-members/builder");
    this.filterBuilders.teamMembers = response.data.pageList;
    this.$refs.table.createDragAndDropSorting();
  },

  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active
      }
      this.axios.post("team-members/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Member updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },


    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        await this.axios.delete("team-members/delete/" + this.toDeleteId);
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Team Member deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },

    async reorder(data) {
      try {
        let body = [];
        data.forEach((row, i) => {
          body.push({"id": row.id, "sort_order": i + 1})
        });

        await this.axios.post("team-members/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.member_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },

  },
}
</script>

